// @flow
import { NoIndexMetaTag, PageContent, SEO } from "@components";
import { Analytics } from "@containers";
import { Constants } from "@utils";
import { StorageContext } from "@utils/BrowserStorage/StorageContext";
import { LEAD_SCORING_DEFAULT_VALUES, PAGE_TYPES } from "@utils/Constants";
import { LocaleContext } from "@utils/Localization/LocaleContext";
import withURLParameters from "@utils/ParametersHOC";
import { graphql } from "gatsby";
import React from "react";

import { determineStyle } from "../utils";
import { PageContext } from "../utils/DynamicArticles";
import { loadLocalStorageId } from "../utils/LocalStorage";

const PageHomeTemplate = ({
    data: {
        page,
        channel,
        market,
        navigation,
        footer,
        site: {
            siteMetadata: { siteUrl },
        },
        translations: { nodes: translations },
    },
    query,
    location,
}: *) => {
    const navData = determineStyle(
        page?.navigationRendering,
        page.navigation || navigation,
    );
    // eslint-disable-next-line no-console
    return (
        <LocaleContext.Provider
            value={{
                language: page.node_locale,
                market,
            }}
        >
            <PageContext.Provider value={page}>
                <StorageContext.Provider
                    value={loadLocalStorageId(Constants.QUERY_PARAMETERS)}
                >
                    <Analytics.PageWrapper
                        key={page.id}
                        entry={page.id}
                        type={PAGE_TYPES.HOME}
                        title={page.title}
                        language={page.node_locale}
                        urlParameters={query}
                        slug="/"
                        segments={page.segments}
                        leadScoringValue={
                            page.leadScoringValue ||
                            LEAD_SCORING_DEFAULT_VALUES[PAGE_TYPES.HOME]
                        }
                    >
                        <SEO
                            title={page.title}
                            translations={translations}
                            publishedMarkets={page.markets}
                            metaDescription={page.metaDescription}
                            siteUrl={siteUrl}
                            currentPath={location.pathname}
                            page={page}
                            market={market}
                            isHomePage
                        />
                        <NoIndexMetaTag page={page} channel={channel} />
                        <PageContent
                            navigation={navData}
                            footer={footer}
                            entry={page}
                            publishedMarkets={page.markets}
                            enableScrollTop={page.enableScrollTop}
                            hideCategoryBar
                            offer={page.offer}
                            hideCategoryBarMobile
                            disclaimer={page.disclaimer}
                            disablePip={page.disablePip}
                        />
                    </Analytics.PageWrapper>
                </StorageContext.Provider>
            </PageContext.Provider>
        </LocaleContext.Provider>
    );
};

export default withURLParameters(PageHomeTemplate);

export const query = graphql`
    query(
        $id: String!
        $language: String!
        $marketCode: String!
        $channel: String!
        $categoryId: String = null
    ) {
        ...SiteMetadata
        page: contentfulPageHome(
            contentful_id: { eq: $id }
            node_locale: { eq: $language }
        ) {
            id
            contentful_id
            __typename
            title
            node_locale
            metaDescription
            navigationRendering
            hero: heroMarketSpecific(marketCode: $marketCode) {
                ...DisplayHero
            }
            heroVariant: hero {
                ...VariantContainer
            }
            content {
                ...DisplaySection
                ...Content
                ...VariantContainer
                ...ContentSelector
                ...DisplayExpandableCard
            }
            disablePip
            displaySticky {
                ...DisplaySticky
                ...VariantContainer
            }
            enableScrollTop
            markets {
                ...MarketGeneral
            }
            segments
            leadScoringValue
            allDossiersSlug
            offer(locale: $language, marketCode: $marketCode) {
                ...PromotionalOffer
            }
            dossier(marketCode: $marketCode) {
                node_locale
                slug
                title
                pageHeader {
                    ... on ContentfulDisplayHeader {
                        __typename
                        description {
                            raw
                            references {
                                __typename
                                ... on ContentfulPage {
                                    contentful_id
                                    slug
                                    channels {
                                        channel
                                    }
                                }
                                ... on ContentfulPageLanding {
                                    contentful_id
                                    slug
                                    channels {
                                        channel
                                    }
                                }
                                ... on ContentfulPageExperiencePage {
                                    contentful_id
                                    slug
                                    channels {
                                        channel
                                    }
                                }
                            }
                        }
                    }
                    ... on ContentfulDisplayHero {
                        __typename
                        contentful_id
                        rtTitle {
                            raw
                        }
                        tagline
                    }
                }
                entry {
                    ... on ContentfulDossier {
                        ...DossierGeneral
                        editions: dossierEditions(marketCode: $marketCode) {
                            __typename
                            articles: marketSpecificArticles(
                                marketCode: $marketCode
                                language: $language
                            )
                            experts(
                                language: $language
                                marketCode: $marketCode
                            )
                        }
                    }
                }
            }
            noIndex
            disclaimer {
                ...Disclaimer
            }
        }
        channel: contentfulChannel(channel: { eq: $channel }) {
            noIndex
        }
        market: contentfulMarket(
            code: { eq: $marketCode }
            node_locale: { eq: $language }
        ) {
            ...MarketGeneral
        }
        translations: allTpoContentfulLocale(
            filter: { code: { ne: $language } }
        ) {
            nodes {
                node_locale: code
            }
        }
        navigation: contentfulNavigation(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            default: { eq: true }
        ) {
            ...Navigation
        }

        footer: contentfulFooter(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            default: { eq: true }
        ) {
            ...Footer
        }
    }
`;
